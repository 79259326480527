import React from "react"
import {
  SiteTitle,
  Section,
  Copyright,
  BackButton,
  RichText,
  MenuDrawer,
} from "../components"
import Card from "@material-ui/core/Card"
import { dark, accent } from "../theme/colors"
import styled from "styled-components"
import logo from "../assets/equals-rounded.svg"
import BackgroundImage from "gatsby-background-image"
import { Typography, Grid, Box } from "@material-ui/core"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import theme from "../theme"

export const VerhuurPage = ({ data }) => {
  const {
    title,
    background,
    introduction,
    overigVerhuurSectionTitle,
    overigVerhuurIntroTekst,
    overigVerhuur,
    zalen,
  } = data.contentfulVerhuurPage
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]

  const BlendBackground = styled(BackgroundImage)`
    background-blend-mode: color;
  `

  const ZaalImage = styled(Image)`
    border-radius: 5px;
    width: 93%;
    margin: 0;
    padding: ${theme.spacing(1)}px;
    background-color: ${accent};
  `

  const ContainerGrid = styled(Grid)`
    margin: ${theme.spacing(2)}px;
    width: 100%;
    padding: 1.5em;

    @media only screen and (max-width: 960px) {
      margin: 0px;
    }
  `

  const Property = styled.li`
    font-size: 1rem;
  `

  return (
    <React.Fragment>
      <MenuDrawer />
      <SiteTitle title="Zaalverhuur" />

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            horizontalSpacing={3}
            verticalSpacing={10}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>
        {/* Rich Text Section */}
        <Section
          display="flex"
          flexGrow={1}
          horizontalSpacing={3}
          verticalSpacing={1.5}
        >
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={6}>
              <Typography variant="h2" gutterBottom>
                {title}
              </Typography>
              <RichText>{introduction.json}</RichText>
            </Grid>
          </Grid>
        </Section>
        <Section horizontalspacing={3} verticalspacing={2}>
          <Grid container spacing={2} direction="row" justify="center">
            <Grid item direction="column" xs={12} sm={10} md={8} xl={8}>
              {zalen.map((entry) => (
                <Box mb={2} key={entry.naam}>
                  <Card spacing={3}>
                    <ContainerGrid spacing={2} container>
                      <Typography gutterBottom variant="h4">
                        {entry.naam}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                          <ZaalImage fluid={entry.foto1.fluid} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <ZaalImage fluid={entry.foto2.fluid} />
                        </Grid>
                      </Grid>
                      <ul>
                        {entry.eigenschappen.map((eigenschap) => (
                          <Property key={eigenschap.id}>
                            {eigenschap.content}
                          </Property>
                        ))}
                      </ul>
                    </ContainerGrid>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Section>
        <Section horizontalspacing={3} verticalspacing={2} slant="dark">
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={6}>
              <Typography variant="h4">{overigVerhuurSectionTitle}</Typography>
              <RichText>{overigVerhuurIntroTekst.json}</RichText>
              <ul>
                {overigVerhuur.map((entry) => (
                  <Property key={entry}>{entry}</Property>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Section>

        <Section color="dark" slantCenter noPaddingTop>
          <Copyright />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getVerhuurPage {
    contentfulVerhuurPage {
      title
      introduction {
        json
      }
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      overigVerhuurSectionTitle
      overigVerhuurIntroTekst {
        json
      }
      overigVerhuur
      zalen {
        naam
        eigenschappen {
          id
          content
        }
        foto1 {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        foto2 {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default VerhuurPage
